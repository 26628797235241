export const InternationRoutes = [
    { from: 'Lahore', to: 'London',departCode :'LHE' ,ArrivalCode:'LHR' },
    { from: 'Lahore', to: 'Dubai',departCode :'LHE' ,ArrivalCode:'DXB'},
    { from: 'Lahore', to: 'Toronto',departCode :'LHE' ,ArrivalCode:'YTZ' },
    { from: 'Lahore', to: 'NewYork',departCode :'LHE' ,ArrivalCode:'JFK' },
    { from: 'Lahore', to: 'Boston',departCode :'LHE' ,ArrivalCode:'BOS' },
    { from: 'Lahore', to: 'Perth',departCode :'LHE' ,ArrivalCode:'PER' },
    { from: 'Lahore', to: 'Manchester',departCode :'LHE' ,ArrivalCode:'MAN' },
    { from: 'Lahore', to: 'Melbourne',departCode :'LHE' ,ArrivalCode:'AVV' },
    { from: 'Lahore', to: 'Atlanta',departCode :'LHE' ,ArrivalCode:'ATL'},
    { from: 'Lahore', to: 'Doha',departCode :'LHE' ,ArrivalCode:'DOH' },
    { from: 'Lahore', to: 'Sydney',departCode :'LHE' ,ArrivalCode:'SYD' },
    { from: 'Peshawar', to: 'London',departCode :'PEW' ,ArrivalCode:'LHR' },
    { from: 'Peshawar', to: 'Dubai',departCode :'PEW' ,ArrivalCode:'DXB' },
    { from: 'Peshawar', to: 'Toronto',departCode :'PEW' ,ArrivalCode:'YTZ' },
    { from: 'Peshawar', to: 'NewYork',departCode :'PEW' ,ArrivalCode:'JFK' },
    { from: 'Peshawar', to: 'Boston',departCode :'PEW' ,ArrivalCode:'BOS' },
    { from: 'Peshawar', to: 'Perth',departCode :'PEW' ,ArrivalCode:'PER' },
    { from: 'Peshawar', to: 'Manchester',departCode :'PEW' ,ArrivalCode:'MAN' },
    { from: 'Peshawar', to: 'Melbourne',departCode :'PEW' ,ArrivalCode:'AVV'  },
    { from: 'Peshawar', to: 'Atlanta',departCode :'PEW' ,ArrivalCode:'ATL' },
    { from: 'Peshawar', to: 'Doha',departCode :'PEW' ,ArrivalCode:'DOH'  },
    { from: 'Peshawar', to: 'LosAngeles',departCode :'PEW' ,ArrivalCode:'CCD' },
    { from: 'Peshawar', to: 'Jeddah',departCode :'PEW' ,ArrivalCode:'JED' },
    { from: 'Peshawar', to: 'Istanbul', departCode :'PEW' ,ArrivalCode:'IST' },
    { from: 'Faisalabad', to: 'LosAngeles',departCode :'LYP' ,ArrivalCode:'CCD' },
    { from: 'Faisalabad', to: 'Jeddah',departCode :'LYP' ,ArrivalCode:'JED' },
    { from: 'Faisalabad', to: 'Istanbul',departCode :'LYP' ,ArrivalCode:'IST' },
    { from: 'Karachi', to: 'London',departCode :'KHI' ,ArrivalCode:'LHR' },
    { from: 'Karachi', to: 'Dubai',departCode :'KHI' ,ArrivalCode:'DXB' },
    { from: 'Karachi', to: 'Toronto',departCode :'KHI' ,ArrivalCode:'YTZ'  },
    { from: 'Karachi', to: 'NewYork',departCode :'KHI' ,ArrivalCode:'JFK' },
    { from: 'Karachi', to: 'Boston',departCode :'KHI' ,ArrivalCode:'BOS' },
    { from: 'Karachi', to: 'Perth',departCode :'KHI' ,ArrivalCode:'PER' },
    { from: 'Karachi', to: 'Manchester',departCode :'KHI' ,ArrivalCode:'MAN' },
    { from: 'Karachi', to: 'Melbourne',departCode :'KHI' ,ArrivalCode:'AVV'  },
    { from: 'Karachi', to: 'Atlanta', departCode :'KHI' ,ArrivalCode:'ATL' },
    { from: 'Karachi', to: 'Doha',departCode :'KHI' ,ArrivalCode:'DOH' },
    { from: 'Karachi', to: 'LosAngeles',departCode :'KHI' ,ArrivalCode:'CCD'  },
    { from: 'Karachi', to: 'Jeddah',departCode :'KHI' ,ArrivalCode:'JED' },
    { from: 'Karachi', to: 'Istanbul' ,departCode :'KHI' ,ArrivalCode:'IST'},
    { from: 'Quetta', to: 'London', departCode: 'UET', ArrivalCode: 'LHR' },
    { from: 'Quetta', to: 'Dubai', departCode: 'UET', ArrivalCode: 'DXB' },
    { from: 'Quetta', to: 'Toronto', departCode: 'UET', ArrivalCode: 'YTZ' },
    { from: 'Quetta', to: 'NewYork', departCode: 'UET', ArrivalCode: 'JFK' },
    { from: 'Quetta', to: 'Boston', departCode: 'UET', ArrivalCode: 'BOS' },
    { from: 'Quetta', to: 'Perth', departCode: 'UET', ArrivalCode: 'PER' },
    { from: 'Quetta', to: 'Manchester', departCode: 'UET', ArrivalCode: 'MAN' },
    { from: 'Quetta', to: 'Melbourne', departCode: 'UET', ArrivalCode: 'AVV' },
    { from: 'Quetta', to: 'Atlanta', departCode: 'UET', ArrivalCode: 'ATL' },
    { from: 'Quetta', to: 'Doha', departCode: 'UET', ArrivalCode: 'DOH' },
    { from: 'Quetta', to: 'LosAngeles', departCode: 'UET', ArrivalCode: 'LAX' },
    { from: 'Quetta', to: 'Jeddah', departCode: 'UET', ArrivalCode: 'JED' },
    { from: 'Quetta', to: 'Istanbul', departCode: 'UET', ArrivalCode: 'IST' },
  
    { from: 'Islamabad', to: 'London', departCode: 'ISB', ArrivalCode: 'LHR' },
    { from: 'Islamabad', to: 'Dubai', departCode: 'ISB', ArrivalCode: 'DXB' },
    { from: 'Islamabad', to: 'Toronto', departCode: 'ISB', ArrivalCode: 'YTZ' },
    { from: 'Islamabad', to: 'NewYork', departCode: 'ISB', ArrivalCode: 'JFK' },
    { from: 'Islamabad', to: 'Boston', departCode: 'ISB', ArrivalCode: 'BOS' },
    { from: 'Islamabad', to: 'Perth', departCode: 'ISB', ArrivalCode: 'PER' },
    { from: 'Islamabad', to: 'Manchester', departCode: 'ISB', ArrivalCode: 'MAN' },
    { from: 'Islamabad', to: 'Melbourne', departCode: 'ISB', ArrivalCode: 'AVV' },
    { from: 'Islamabad', to: 'Atlanta', departCode: 'ISB', ArrivalCode: 'ATL' },
    { from: 'Islamabad', to: 'Doha', departCode: 'ISB', ArrivalCode: 'DOH' },
    { from: 'Islamabad', to: 'LosAngeles', departCode: 'ISB', ArrivalCode: 'LAX' },
    { from: 'Islamabad', to: 'Jeddah', departCode: 'ISB', ArrivalCode: 'JED' },
    { from: 'Islamabad', to: 'Istanbul', departCode: 'ISB', ArrivalCode: 'IST' },
  
    { from: 'Multan', to: 'London', departCode: 'MUX', ArrivalCode: 'LHR' },
    { from: 'Multan', to: 'Dubai', departCode: 'MUX', ArrivalCode: 'DXB' },
    { from: 'Multan', to: 'Toronto', departCode: 'MUX', ArrivalCode: 'YTZ' },
    { from: 'Multan', to: 'NewYork', departCode: 'MUX', ArrivalCode: 'JFK' },
    { from: 'Multan', to: 'Boston', departCode: 'MUX', ArrivalCode: 'BOS' },
    { from: 'Multan', to: 'Perth', departCode: 'MUX', ArrivalCode: 'PER' },
    { from: 'Multan', to: 'Manchester', departCode: 'MUX', ArrivalCode: 'MAN' },
    { from: 'Multan', to: 'Melbourne', departCode: 'MUX', ArrivalCode: 'AVV' },
    { from: 'Multan', to: 'Atlanta', departCode: 'MUX', ArrivalCode: 'ATL' },
    { from: 'Multan', to: 'Doha', departCode: 'MUX', ArrivalCode: 'DOH' },
    { from: 'Multan', to: 'LosAngeles', departCode: 'MUX', ArrivalCode: 'LAX' },
    { from: 'Multan', to: 'Jeddah', departCode: 'MUX', ArrivalCode: 'JED' },
    { from: 'Multan', to: 'Istanbul', departCode: 'MUX', ArrivalCode: 'IST' },
  ];